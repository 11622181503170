// BOGO -- Buy-One-Get-One-Free Special on Coffee. (Unlimited)
const BOGO = { code: 'BOGO', price: -11.23 }
// APPL -- If you buy 3 or more bags of Apples, the price drops to $4.50.
const APPL = { code: 'APPL', price: -1.5 }
// CHMK -- Purchase a box of Chai and get milk free. (Limit 1)
const CHMK = { code: 'CHMK', price: -4.75 }
// APOM -- Purchase a bag of Oatmeal and get 50% off a bag of Apples
const APOM = { code: 'APOM', price: -3.0 }

const applyBOGO = (items) => {
  let numCoffees = 0
  items.forEach((item) => {
    if (item.code === 'CF1') numCoffees += 1
  })
  // one cupon for every 2 coffees in the cart
  const numCupons = Math.floor(numCoffees / 2)
  if (numCupons > 0) {
    return Array(numCupons).fill(BOGO)
  }
  return []
}

const applyAPPL = (items) => {
  let numApples = 0
  items.forEach((item) => {
    if (item.code === 'AP1') numApples += 1
  })
  // applied for every apply but only if there are at least 3
  if (numApples >= 3) {
    return Array(numApples).fill(APPL)
  }
  return []
}

const applyCHMK = (items) => {
  // check for chai
  let chaiIndex = items.findIndex((cartItem) => cartItem.code === 'CH1')
  let milkIndex = items.findIndex((cartItem) => cartItem.code === 'MK1')
  if (chaiIndex > -1 && milkIndex > -1) {
    // we have a chai and a milk, add one cupon
    return [CHMK]
  }
  return []
}

const applyAPOM = (items) => {
  // I guess this can be unlimited???
  let numOatmeal = 0
  let numApples = 0
  let discounts = []
  items.forEach((item) => {
    if (item.code === 'OM1') numOatmeal += 1
    if (item.code === 'AP1') numApples += 1
  })
  // for each oatmeal, they can get 50% if they have matching apple bag
  if (numOatmeal >= 1 && numApples >= 1) {
    for (let i = 0; i < numOatmeal; i += 1) {
      if (numApples > 0) {
        discounts.push(APOM)
      }
      numApples -= 1
    }
  }
  return discounts
}

export const analyzeForDiscounts = (cart) => {
  let allDiscounts = [
    ...applyBOGO(cart),
    ...applyAPPL(cart),
    ...applyCHMK(cart),
    ...applyAPOM(cart),
  ]
  return allDiscounts
}

export const getCartTotal = (items, cupons) => {
  let total = 0
  items.map((item) => (total += item.price))
  cupons.map((item) => (total += item.price))
  return total.toFixed(2)
}
