import { getProductIcon } from '../../helpers'

const Product = ({ item, onClick }) => (
  <li className='product-list__item'>
    <div className='item-details'>
      <span className='item-icon'>{getProductIcon(item.code)}</span>
      <span className='item-name'>{item.name}</span>
      <span className='item-code'>{item.code}</span>
    </div>
    <span className='item-price'>${item.price.toFixed(2)}</span>
    {onClick && (
      <button className='btn item-add' onClick={() => onClick(item)}>
        + Add to Cart
      </button>
    )}
  </li>
)

export default Product
