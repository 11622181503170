import {
  GiCoffeeCup,
  GiCoffeeMug,
  GiMilkCarton,
  GiOat,
  GiShinyApple,
  GiShoppingBag,
} from 'react-icons/gi'

export const getProductIcon = (code) => {
  switch (code) {
    case 'CH1':
      return <GiCoffeeMug />
    case 'AP1':
      return <GiShinyApple />
    case 'CF1':
      return <GiCoffeeCup />
    case 'MK1':
      return <GiMilkCarton />
    case 'OM1':
      return <GiOat />
    default:
      return <GiShoppingBag />
  }
}
