import { getCartTotal } from '../../cartLogic'
import { FaShoppingCart } from 'react-icons/fa'
import Product from '../Product'
import './styles.css'

const Cart = ({ cupons, items, removeFromCart }) => {
  // const updateOrder = (item) => {
  //   removeFromCart(item)
  // }

  return (
    <div className='cart column'>
      <div className='column-title'>
        <FaShoppingCart />
        Cart Summary
      </div>
      <ul className='products-list'>
        {items.map((item) => (
          <Product item={item} />
        ))}
        {cupons.map((item) => (
          <Coupon item={item} />
        ))}
      </ul>
      <div className='cart-total'>
        <h3>
          Total
          <div className='cart-total-dollars'>
            ${getCartTotal(items, cupons)}
          </div>
        </h3>
      </div>
    </div>
  )
}

const Coupon = ({ item }) => {
  let price = item.price * -1
  return (
    <li className='product-list__cupon'>
      <span className='item-name'>
        {item.name} ({item.code})
      </span>
      <span className='item-price'>-${price.toFixed(2)}</span>
    </li>
  )
}

export default Cart
