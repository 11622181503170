import React, { useState } from 'react'
import Products from './components/Products'
import Cart from './components/Cart'
import stocks from './data/stocks'
import { analyzeForDiscounts } from './cartLogic'
import './App.css'

function App() {
  const [cart, setCart] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [products, setProducts] = useState(stocks)
  const [cupons, setCupons] = useState([])

  const addToCart = (item) => {
    const newCart = [...cart, item]
    setCart(newCart)
    setCupons(analyzeForDiscounts(newCart))
  }
  const removeFromCart = (item) => {
    // this is going to remove the first index of that item found
    // update to remove specific item clicked
    const itemIndex = cart.findIndex((cartItem) => cartItem.code === item.code)
    setCart([...cart.slice(0, itemIndex), ...cart.slice(itemIndex + 1)])
  }

  return (
    <div className='App'>
      <header className='App-header'>Farmers Market Checkout</header>
      <div className='App-columns'>
        <Products items={products} addToCart={addToCart} />
        <Cart items={cart} cupons={cupons} removeFromCart={removeFromCart} />
      </div>
    </div>
  )
}

export default App

// add items to cart
// total items in cart
// apply discounts to cart
