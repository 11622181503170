// current stock items for market
const stocks = [
  {
    code: 'CH1',
    name: 'Chai',
    price: 3.11,
  },
  {
    code: 'AP1',
    name: 'Apples',
    price: 6.0,
  },
  {
    code: 'CF1',
    name: 'Coffee',
    price: 11.23,
  },
  {
    code: 'MK1',
    name: 'Milk',
    price: 4.75,
  },
  {
    code: 'OM1',
    name: 'Oatmeal',
    price: 3.69,
  },
]

export default stocks
