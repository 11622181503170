import Product from '../Product'

const Products = ({ addToCart, items }) => {
  const updateOrder = (item) => {
    addToCart(item)
  }

  return (
    <div className='products column'>
      <div className='column-title'>Products</div>
      <ul className='products-list'>
        {items.map((item) => (
          <Product item={item} onClick={updateOrder} />
        ))}
      </ul>
    </div>
  )
}

export default Products
